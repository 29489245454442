import React, { useEffect } from "react";

import Logo from "../../assets/images/logo/dark-h.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Button, Col, Form, Input, Row, Upload, Dropdown } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import Config from "../../Config";

function Dashboard(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const FullName = localStorage.getItem("Full_Name");
  const CompanyID = localStorage.getItem("CompanyID");

  const navigate = useNavigate();

  useEffect(() => {
    const CompanyName = localStorage.getItem("CompanyName");
    if (!AccessKey || !UserID || !FullName) {
      navigate("/");
    }
  }, []);


  // console.log(rolesObject);
  // if (rolesObject.hasOwnProperty('Admin') || rolesObject.hasOwnProperty('Manager')) {
  //     console.log(true);
  // } else {
  //     console.log(false);
  // }

  // Check the retrieved roles
  //console.log('Retrieved Roles:', storedRoles);

  // Example usage: Check if 'Admin' role exists
  // const storedRoles = JSON.parse(localStorage.getItem('roles'));
  // if (storedRoles.includes('Admin')) {
  //     console.log('User has the Admin role');
  // }
  // else {
  //     console.log('User has the Manager role');

  // }


  // const fetchCompany = async () => {
  //     const response = await axios.get(Config.base_url + `CompanyInfo/GetCompany/${CompanyID}`, {
  //         headers: {
  //             Authorization: `Bearer ${AccessKey}`
  //         }
  //     })
  //     console.log(response.data.companyData)
  //     localStorage.setItem("CompanyCode", response.data.companyData.companyCode);
  //     localStorage.setItem("CompanyID", response.data.companyData.id);
  //     localStorage.setItem("CompanyName", response.data.companyData.name);
  //     localStorage.setItem("CompanyAddress", response.data.companyData.address);
  // }

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  const items = [
    {
      key: "1",
      label: (
        <a rel="noopener noreferrer" href="#">
          Edit Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a rel="noopener noreferrer" href="#" onClick={handleSignOut}>
          Sign Out
        </a>
      ),
      // icon: <SmileOutlined />,
    },
  ];

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Dashboard</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
