import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  Button,
  DatePicker,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Config from "../../Config";
import axios from "axios";
import dayjs from "dayjs";
import BanksMenu from "./BanksMenu";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
const AddTransfers = () => {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const navigator = useNavigate();
  const [form] = Form.useForm();
  const [formMain] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [AccountCode, setAccountCode] = useState("");
  const [AccountDesc, setAccountDesc] = useState("");
  const [AccountsDropdown, setAccountsDropdown] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [Level, setLevel] = useState("0");
  const [OrderBy, setOrderBy] = useState("");
  const [AccountID, setAccountID] = useState("");
  const [BankPaymentList, setBankPaymentList] = useState([]);
  const [BankMode, setBankMode] = useState([]);
  const [ListOfBank, setListOfBank] = useState([]);
  const [OpenDate, setOpenDate] = useState(null);
  const [TransferForm] = useForm();

  useEffect(() => {
    document.title = "Add Bank Transfer";
    fetchBankAccounts();
    fetchBankMode()
  }, []);

  const onFinish = (value) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      FYear: FYear,
      CompanyID: CompanyID,
      AccountCode: "",
      ...value,
    };
    let url;
    // if (props.CustomerID === undefined) {
    //     url = 'Customer/AddCustomer'
    // }
    // else {
    //     url = 'Customer/UpdateCustomer';
    //     data.ID = props.CustomerID;
    // }

    // console.log(data);

    let api_config = {
      method: "post",

      url: Config.base_url + url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          message.success(response.data.status_message);
          setLoading(false);
          formMain.resetFields();
        } else {
          message.error(response.data.status_message);
          setLoading(false);
        }
      })
      .catch(function (error) { });
  };

  const fetchBankAccounts = async () => {
    try {
      const response = await axios.get(
        Config.base_url +
        `AccountMain/GetBy/${CompanyID}?accountCode=50108&level=3`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setListOfBank(response.data.listofAccounts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBankMode = async () => {
    try {
      const response = await axios.get(
        Config.base_url + `DropdownData/GetDropdownData/0?Type=BankMode`,
        {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code === 1) {
        setBankMode(response.data.dropdownData || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChange = (date, dateString) => {
    setOpenDate(dateString);
    console.log(dateString);
  };


  const columns = (remove) => [
    { title: "Date", dataIndex: "date", key: "date", width: 250 },
    {
      title: "From Bank",
      dataIndex: "fromBank",
      key: "fromBank",
      width: 200,
    },
    {
      title: "Ref No.",
      dataIndex: "refNo",
      key: "refNo",
      width: 140,
    },
    { title: "Mode", dataIndex: "mode", key: "mode", width: 150 },
    {
      title: "To Bank",
      dataIndex: "toBank",
      key: "toBank",
      width: 250,
    },
    { title: "Detail", dataIndex: "detail", key: "detail", width: 200 },
    { title: "Amount", dataIndex: "amount", key: "amount", width: 200 },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, { key }) => (
        <ul className="inline-action">
          <li>
            <Link to={`#/`} onClick={() => remove(key)} className="red">
              <DeleteOutlined />
            </Link>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <div className="sub-menu-wrap">
        <h5>Bank</h5>
        <BanksMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/bank/transfers">
                <ArrowLeftIcon />
              </NavLink>Add Bank Transfer</h3>
          </div>
          <Row>
            <Col xs={24} md={24}>
              <Form
                form={TransferForm}
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  users: [{}],
                }}
              >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      <Table
                        dataSource={fields.map(
                          ({ key, name, fieldKey, ...restField }, index) => ({
                            key,
                            date: (
                              <Form.Item
                                {...restField}
                                name={[name, "date"]}
                                fieldKey={[fieldKey, "date"]}
                              >
                                <DatePicker
                                  defaultValue={
                                    OpenDate === null
                                      ? dayjs()
                                      : dayjs(OpenDate, "YYYY-MM-DD")
                                  }
                                  onChange={handleDateChange}
                                />
                              </Form.Item>
                            ),
                            fromBank: (
                              <Form.Item
                                {...restField}
                                name={[name, "fromBank"]}
                                fieldKey={[fieldKey, "fromBank"]}
                              >
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  variant="borderless"
                                  placeholder="From Bank"
                                  style={{ width: "250px" }}
                                  options={ListOfBank.map(
                                    (record) => ({
                                      label: `${record.accountDescription} (${record.accountCode})`,
                                      value: `${record.accountDescription} (${record.accountCode})`,
                                    })
                                  )}
                                />
                              </Form.Item>
                            ),
                            refNo: (
                              <Form.Item
                                {...restField}
                                name={[name, "refNo"]}
                                fieldKey={[fieldKey, "refNo"]}
                              >
                                <Input
                                  placeholder="Ref No."
                                  variant="borderless"
                                />
                              </Form.Item>
                            ),
                            mode: (
                              <Form.Item
                                {...restField}
                                name={[name, "mode"]}
                                fieldKey={[fieldKey, "mode"]}
                                initialValue="Cash"
                              >
                                <Select
                                  variant="borderless"
                                  placeholder="Mode"
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={BankMode.map((record) => ({
                                    label: record.name,
                                    value: record.name,
                                  }))}
                                />
                              </Form.Item>
                            ),
                            toBank: (
                              <Form.Item
                                {...restField}
                                name={[name, "toBank"]}
                                fieldKey={[fieldKey, "toBank"]}
                              >
                                <Select
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.label
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  variant="borderless"
                                  placeholder="To Bank"
                                  style={{ width: "250px" }}
                                  options={ListOfBank.map(
                                    (record) => ({
                                      label: `${record.accountDescription} (${record.accountCode})`,
                                      value: `${record.accountDescription} (${record.accountCode})`,
                                    })
                                  )}
                                />
                              </Form.Item>
                            ),

                            detail: (
                              <Form.Item
                                {...restField}
                                name={[name, "detail"]}
                                fieldKey={[fieldKey, "detail"]}
                              >
                                <Input
                                  placeholder="Detail"
                                  variant="borderless"
                                />
                              </Form.Item>
                            ),
                            amount: (
                              <Form.Item
                                {...restField}
                                name={[name, "amount"]}
                                fieldKey={[fieldKey, "amount"]}
                              >
                                <Input
                                  placeholder="Amount"
                                  variant="borderless"
                                />
                              </Form.Item>
                            ),
                            action: (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ),
                          })
                        )}
                        columns={columns(remove)}
                        pagination={false}
                        size="small"
                      />

                      <Form.Item>
                        <Button
                          style={{ marginTop: 10 }}
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddTransfers;
