import { DeleteOutlined, DownloadOutlined, PlusOutlined, UploadOutlined, MinusCircleOutlined, DownOutlined } from '@ant-design/icons'
import { ArrowLeftIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { Col, Form, Input, Row, Select, message, Button, DatePicker, Table, Dropdown } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Config from '../../Config'
import axios from 'axios'
import dayjs from 'dayjs'
import BanksMenu from './BanksMenu'

const { Option } = Select;

const AddWHTPayments = () => {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");
    const FYear = localStorage.getItem("DefaultFYear");
    const User = localStorage.getItem("Full_Name");

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [formMain] = Form.useForm();
    const [PaymentForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [OpenDate, setOpenDate] = useState(null);
    const [AccountCode, setAccountCode] = useState("");
    const [AccountDesc, setAccountDesc] = useState("");
    const [AccountsDropdown, setAccountsDropdown] = useState([]);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [Level, setLevel] = useState("0");
    const [OrderBy, setOrderBy] = useState("");
    const [AccountID, setAccountID] = useState("");
    const [BankPaymentList, setBankPaymentList] = useState([]);
    const [ListOfBank, setListOfBank] = useState([]);
    const [BankMode, setBankMode] = useState([]);
    const [ListOfAccounts, setListOfAccounts] = useState([]);


    useEffect(() => {
        document.title = 'Add WHT Payments';
        fetchBankAccounts();
        fetchBankMode();
        fetchAccounts();
    }, []);


    const onFinish = async (value) => {
        setLoading(true);
        try {

            if (!form.getFieldValue("Bank")) {
                message.error("Please select bank.");
                setLoading(false);
                return;
            }
            const fields = PaymentForm.getFieldValue("users");

            for (let i = 0; i < fields.length; i++) {
                if (fields[i].nominalAccount === undefined) {
                    message.error("Please select supplier account.");
                    setLoading(false);
                    return;
                }
                if (fields[i].amount === undefined) {
                    message.error("Please enter amount.");
                    setLoading(false);
                    return;
                }
            }

            const data = fields.map((field) => ({
                ...field,
                date: OpenDate || dayjs().format("YYYY-MM-DD"),
                field1: "",
                field2: "",
                field3: "",
                bank: form.getFieldValue("Bank"),
                refNo: field.refNo || "",
                detail: field.detail || "",
                nominalAccount: field.nominalAccount || "",
                mode: field.mode || "",
                amount: field.amount || 0,
                bankPaymentType: "Expense Payment",
                bankPaymentBy: User,
                companyID: CompanyID,
                isActive: true,
                isDeleted: false,
                bankPayment: false,
                whtPayment: true,
                bankReceipt: false,
            }))

            const response = await axios.post(
                `${Config.base_url}Bank/AddBankPayments`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );

            console.log(response.data);
            if (response.data.status_code === 1) {

                message.success("WHT Payment Added Successfully");
                navigate("/bank/manage");
                setLoading(false);
            }
        }
        catch (error) {
            console.error("Error adding WHT payment:", error);
            message.error("Error adding WHT payment");
            setLoading(false);
        }
    };

    const handleDateChange = (date, dateString) => {
        setOpenDate(dateString);
        console.log(dateString)
    }

    const fetchBankAccounts = async () => {
        try {
            const response = await axios.get(
                Config.base_url +
                `AccountMain/GetBy/${CompanyID}?accountCode=50108&level=3`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (response.data.status_code === 1) {
                setListOfBank(response.data.listofAccounts);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchBankMode = async () => {
        try {
            const response = await axios.get(
                Config.base_url + `DropdownData/GetDropdownData/0?Type=BankMode`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (response.data.status_code === 1) {
                setBankMode(response.data.dropdownData || []);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchAccounts = async () => {
        const api_config = {
            method: "get",
            url: `${Config.base_url}Supplier/GetBy/${CompanyID}`,
            headers: {
                Authorization: `Bearer ${AccessKey}`,
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await axios(api_config);
            if (response.data && response.data.status_code === 1) {
                setListOfAccounts(response.data.listofSuppliers || []);
            } else {
                setListOfAccounts([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setListOfAccounts([]);
        }
    };

    const columns = (remove) => [
        { title: "Date", dataIndex: "date", key: "date", width: 150 },
        {
            title: "Ref No.",
            dataIndex: "refNo",
            key: "refNo",
            width: 100,
        },
        { title: "Mode", dataIndex: "mode", key: "mode", width: 150 },
        { title: "Supplier Account", dataIndex: "nominalAccount", key: "nominalAccount", width: 250 },
        { title: "Detail", dataIndex: "detail", key: "detail", width: 200 },
        { title: "Amount", dataIndex: "amount", key: "amount", width: 200 },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: 110,
            render: (_, { key }) => (
                <ul className="inline-action">
                    <li>
                        <Link to={`# / `} onClick={() => remove(key)} className="red">
                            <DeleteOutlined />
                        </Link>
                    </li>
                </ul>
            ),
        },
    ];

    const items = [
        {
            label: "Approve and New",
            key: "1",
        },
        {
            label: "Approve and Print",
            key: "2",
        },
    ];

    return (
        <>
            <div className="sub-menu-wrap">
                <h5>Bank</h5>
                <BanksMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header">
                        <h3 className="page-title">
                            <NavLink to="/bank/manage">
                                <ArrowLeftIcon />
                            </NavLink>
                            Add WHT Payments</h3>
                    </div>
                    <div className="filters-wrap">
                        <Form
                            form={form}
                        >
                            <Form.Item name="Bank" label='Bank'>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    placeholder="Bank"
                                    style={{ width: "350px" }}
                                    options={ListOfBank.map((fieldThreeitem) => ({
                                        label: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                                        value: `${fieldThreeitem.accountDescription} (${fieldThreeitem.accountCode})`,
                                    }))}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <Row>
                        <Col xs={24} md={24}>
                            <Form
                                form={PaymentForm}
                                name="dynamic_form_nest_item"
                                onFinish={onFinish}
                                autoComplete="off"
                                initialValues={{
                                    users: [{}],
                                }}
                            >
                                <Form.List name="users">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Table
                                                dataSource={fields.map(
                                                    ({ key, name, fieldKey, ...restField }, index) => ({
                                                        key,
                                                        date: (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "date"]}
                                                                fieldKey={[fieldKey, "date"]}
                                                            >
                                                                <DatePicker
                                                                    defaultValue={
                                                                        OpenDate === null
                                                                            ? dayjs()
                                                                            : dayjs(OpenDate, "YYYY-MM-DD")
                                                                    }
                                                                    onChange={handleDateChange}
                                                                />
                                                            </Form.Item>
                                                        ),
                                                        refNo: (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "refNo"]}
                                                                fieldKey={[fieldKey, "refNo"]}
                                                            >
                                                                <Input
                                                                    placeholder="Ref No."
                                                                    variant="borderless"
                                                                />
                                                            </Form.Item>
                                                        ),
                                                        mode: (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "mode"]}
                                                                fieldKey={[fieldKey, "mode"]}
                                                                initialValue="Cash"
                                                            >
                                                                <Select
                                                                    variant="borderless"
                                                                    placeholder="Mode"
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        option.label
                                                                            .toLowerCase()
                                                                            .includes(input.toLowerCase())
                                                                    }
                                                                    options={BankMode.map((fieldThreeitem) => ({
                                                                        label: fieldThreeitem.name,
                                                                        value: fieldThreeitem.name,
                                                                    }))}
                                                                />
                                                            </Form.Item>
                                                        ),
                                                        nominalAccount: (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "nominalAccount"]}
                                                                fieldKey={[fieldKey, "nominalAccount"]}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        option.label
                                                                            .toLowerCase()
                                                                            .includes(input.toLowerCase())
                                                                    }
                                                                    variant="borderless"
                                                                    placeholder="Supplier Account"
                                                                    style={{ width: "320px" }}
                                                                    options={ListOfAccounts.map((fieldThreeitem) => ({
                                                                        label: `${fieldThreeitem.businessName} (${fieldThreeitem.accountCode})`,
                                                                        value: `${fieldThreeitem.businessName} (${fieldThreeitem.accountCode})`,
                                                                    }))}
                                                                />
                                                            </Form.Item>
                                                        ),
                                                        detail: (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "detail"]}
                                                                fieldKey={[fieldKey, "detail"]}
                                                            >
                                                                <Input
                                                                    placeholder="Detail"
                                                                    variant="borderless"
                                                                />
                                                            </Form.Item>
                                                        ),
                                                        amount: (
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "amount"]}
                                                                fieldKey={[fieldKey, "amount"]}
                                                            >
                                                                <Input placeholder="Amount" variant="borderless" />
                                                            </Form.Item>
                                                        ),
                                                        action: (
                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        ),
                                                    })
                                                )}
                                                columns={columns(remove)}
                                                pagination={false}
                                                size="small"
                                            />

                                            <Form.Item>
                                                <Button
                                                    style={{ marginTop: 10 }}
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Form>
                            <Form
                                layout="vertical"
                                className="my-5"
                                form={PaymentForm}
                                onFinish={onFinish}
                            >
                                <Row justify="end" className="text-end">

                                    <Col xs={24} md={{ span: 4, offset: 20 }}>
                                        <Dropdown.Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                            icon={<DownOutlined />}
                                            menu={{ items }} // Dropdown menu items
                                        >
                                            Approve and Close
                                        </Dropdown.Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default AddWHTPayments