import { Form, Input, Select, Table, Button, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  CodeSandboxOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import { Dropdown, Space, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import ProductionMenu from "./ProductionMenu";
import Config from "../../Config";
import axios from "axios";
const ExcelJS = require("exceljs");

function ManageProducts(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [loading, setLoading] = useState(false);
  const [ListOfProducts, setListOfProducts] = useState([]);
  const [OrderBy, setOrderBy] = useState("");
  const [ProductName, setProductName] = useState("");
  const [Type, setType] = useState("");
  const [ProductCode, setProductCode] = useState(1000);

  const [form] = Form.useForm();

  const fetchProducts = async () => {
    setLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}Product/GetBy/${CompanyID}?orderBy=${OrderBy}&code=${ProductCode}&name=${ProductName}&productType=${Type}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setListOfProducts(response.data.listofProducts || []);
      } else {
        setListOfProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setListOfProducts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Manage Products";
    fetchProducts();
  }, [OrderBy, ProductCode, ProductName, Type]);

  const handleFilters = (formData) => {
    console.log("Form Data Submitted:", formData);
    setProductCode(formData["code"] || 1000);
    setProductName(formData["name"] || "");
    setOrderBy(formData["OrderBy"] || "");
    setType(formData["productType"] || "");
    console.log("Type State after set:", formData["productType"]);
    // fetchAccounts();
  };

  const deleteAccount = async (sno) => {
    try {
      const accountToUpdate = ListOfProducts.find((u) => u.id === sno);
      if (!accountToUpdate) {
        message.error("Product not found!");
        return;
      }

      const updatedAccount = {
        ...accountToUpdate,
        isActive: false,
        isDeleted: true,
      };

      await axios.patch(
        `${Config.base_url}Product/UpdateRecord/${sno}`,
        updatedAccount,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      setListOfProducts((prev) => prev.filter((account) => account.id !== sno));
      message.success("Product deleted successfully.");
      fetchProducts();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const onReset = () => {
    form.resetFields();
    setProductCode("1000");
    setProductName("");
    setType("");
    setOrderBy("");
    // fetchAccounts(); // Fetch all data again without any filters
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "productType",
      key: "productType",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Sale Price",
      dataIndex: "salePrice",
      key: "salePrice",
    },
    {
      title: "Qty on Hand",
      dataIndex: "openingQuantity",
      key: "openingQuantity",
    },
    {
      title: "Low Stock",
      dataIndex: "lowStockLevel",
      key: "lowStockLevel",
    },
    {
      title: "Action",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) => (
        <div className="table-actions">
          <NavLink
            className="primary"
            to={
              record.productType === "Stock"
                ? `/product/edit-stock-products/${record.id}`
                : `/product/edit-Nonstock-products/${record.id}`
            }
          >
            <EditOutlined />
          </NavLink>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this account?"
            onConfirm={() => deleteAccount(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const items = [
    {
      label: (
        <NavLink to="/production/add-stock-products">Stock Products</NavLink>
      ),
      key: "1",
      icon: <CodeSandboxOutlined />,
    },
    {
      label: (
        <NavLink to="/production/add-non-stock-products">
          Non Stock Products
        </NavLink>
      ),
      key: "2",
      icon: <CodeSandboxOutlined />,
    },
  ];
  const menuProps = {
    items,
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Products");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Product Name", key: "name", width: 30 },
      { header: "ProductType", key: "productType", width: 30 },
      { header: "Category", key: "category", width: 30 },
      { header: "SalePrice", key: "salePrice", width: 30 },
      { header: "OpeningQuantity", key: "openingQuantity", width: 30 },
      { header: "LowLevelStock", key: "lowStockLevel", width: 5 },
    ];

    // Add rows to the sheet
    ListOfProducts.forEach((product, index) => {
      sheet.addRow({
        sr: index + 1,
        name: product.name,
        productType: product.productType,
        category: product.category,
        salePrice: product.salePrice,
        openingQuantity: product.openingQuantity,
        lowStockLevel: product.lowStockLevel,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `ProductsList_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const sortedData = ListOfProducts.sort((a, b) => {
    if (a.code < b.code) return 1;
    if (a.code > b.code) return -1;
    return 0;
  });
  return (
    <>
      <div className="sub-menu-wrap">
        <h5>Production</h5>
        <ProductionMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Manage Products</h3>
            <div className="header-actions">
              <NavLink to="/products/import">
                <Button type="dashed" icon={<DownloadOutlined />}>
                  Import
                </Button>
              </NavLink>
              <Button
                type="dashed"
                onClick={handleExport}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
              <NavLink>
                <Dropdown menu={menuProps}>
                  <Button type="primary">
                    <Space>
                      <PlusOutlined />
                      New
                    </Space>
                  </Button>
                </Dropdown>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form onFinish={handleFilters} form={form} layout="vertical">
              <Form.Item name="OrderBy">
                <Select placeholder="Order By" style={{ width: 120 }}>
                  <Select.Option value="code">Product Code</Select.Option>
                  <Select.Option value="name">Product Name</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="productType">
                <Select placeholder="Type" style={{ width: 120 }}>
                  <Select.Option value="">All Types</Select.Option>
                  <Select.Option value="Stock">Stock</Select.Option>
                  <Select.Option value="NonStock">Non Stock</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="name">
                <Input placeholder="Product Name" />
              </Form.Item>
              <Form.Item name="code">
                <Input placeholder="Code" />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Filter
              </Button>
              <Button type="link" onClick={onReset}>
                Reset
              </Button>
            </Form>
          </div>

          <Table
            columns={columns}
            dataSource={sortedData}
            size="small"
            loading={loading}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
}

export default ManageProducts;
