import { Form, Input, Table, Button, Modal, message, } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import { Link, NavLink } from "react-router-dom";
import BanksMenu from "./BanksMenu";
import Config from "../../Config";
import axios from "axios";

function AccountBalance() {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const CompanyID = localStorage.getItem("CompanyID");
    const FYear = localStorage.getItem("DefaultFYear");

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    // pagination
    const [ListOfBank, setListOfBank] = useState([]);
    const [BankAccount, setBankAccount] = useState([]);

    const [ModalForm] = Form.useForm();

    useEffect(() => {
        document.title = "Account Balances";
        fetchBankAccounts();
        GetBankAccountsData();
    }, []);

    const fetchBankAccounts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                Config.base_url +
                `Bank/GetAccountBalances/${CompanyID}?level=3&accountCode=50108`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );

            console.log(response.data);
            setListOfBank(response.data);
        } catch (error) {
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    };

    const AddNewBankAccount = async (FormData) => {
        const data = {
            ...FormData,
            accountCode: (
                parseInt(BankAccount[BankAccount.length - 1].accountCode) + 1
            ).toString(),
            accountDescription: FormData.bankAccount,
            iLevel: 3,
            remarks: FormData.bankAccount + FormData.description,
            year: FYear,
            isActive: true,
            isDeleted: false,
            companyID: CompanyID,
        };

        const encodedbankAccount = encodeURIComponent(FormData.bankAccount);

        const apiUrl =
            Config.base_url +
            `AccountMain/GetBy/${CompanyID}?accountCode=50108&accountName=${encodedbankAccount}&level=3`;
        const response = await axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${AccessKey}`,
            },
        });

        const length = response.data.listofAccounts.length;
        if (length > 0) {
            message.error("Bank Account with same name already exist");
            ModalForm.resetFields();
            return;
        }

        try {
            const response = await axios.post(
                Config.base_url + `AccountMain/AddAccount`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );

            if (response.data.status_code === 1) {
                message.success("Bank Account Added Successfully");
                // Reset the form field
                ModalForm.resetFields();
                fetchBankAccounts();
            }
        } catch (error) {
            console.error(error);
            message.error("Error in Adding Bank Account");
        } finally {
            setOpen(false);
        }
    };

    const GetBankAccountsData = async () => {
        try {
            const response = await axios.get(
                Config.base_url +
                `AccountMain/GetBy/${CompanyID}?accountCode=50108&level=3`,
                {
                    headers: {
                        Authorization: `Bearer ${AccessKey}`,
                    },
                }
            );
            if (response.data.status_code === 1) {
                setBankAccount(response.data.listofAccounts);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const columns = [
        {
            title: 'Sr#',
            dataIndex: '',
            key: 'SR',
            render: (_, record, index) => (
                index + 1
            )
        },
        {
            title: 'Bank',
            dataIndex: 'accountDescription',
            key: 'accountDescription',
        },
        {
            title: 'Code',
            dataIndex: 'accountCode',
            key: 'accountCode',
        },
        {
            title: 'Reconcile Date',
            dataIndex: 'Bank',
            key: 'Bank',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
        },
    ];

    const handleCancel = () => {
        setOpen(false);

    };
    const ShowModal = () => {
        setOpen(true);
    }

    return (
        <>
            {/* Add New Bank Account */}
            <Modal
                title="Bank Account New"
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    layout="vertical"
                    form={ModalForm}
                    onFinish={AddNewBankAccount}
                >

                    {/* Nominal Account */}
                    <Form.Item
                        label="Bank Account"
                        name="bankAccount"
                        rules={[
                            {
                                required: true,
                                message: "Please input the bank account!",
                            },
                        ]}
                    >
                        <Input placeholder="Bank Account" />
                    </Form.Item>

                    {/* Code */}
                    <Form.Item label="Code" name="code">
                        <Input placeholder="Code" disabled />
                    </Form.Item>

                    {/* Description */}
                    <Form.Item label="Description" name="description">
                        <Input placeholder="Description" />
                    </Form.Item>

                    {/* Form Actions */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button
                            type="default"
                            style={{ marginLeft: "8px" }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div className="sub-menu-wrap">
                <h5>Bank</h5>
                <BanksMenu />
            </div>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header">
                        <h3 className="page-title">Account Balances</h3>
                        <div className="header-actions">
                            <Button type="primary" onClick={ShowModal} icon={<PlusOutlined />} >
                                New Account
                            </Button>
                        </div>
                    </div>

                    <Table columns={columns} dataSource={ListOfBank} size="small" loading={loading} pagination={true} />
                </div>
            </div>

        </>
    );
}
export default AccountBalance
