import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
} from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomerMenu from "./CustomerMenu";
import SalesMenu from "../Sales/SalesMenu";
import axios from "axios";
import Config from "../../Config";
import dayjs from "dayjs";

const CustomerReport = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [CustomerForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [CustomerLoading, setCustomerLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [customerAccountCode, setCustomerAccountCode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false); // New state variable
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");

  const fetchCustomerTransactions = async (FormData) => {
    setLoading(true);
    setError(null);

    const effectiveStartDate =
      FormData.period === "custom" && FormData.startDate
        ? dayjs(FormData.startDate).format("YYYY-MM-DD")
        : defaultStartDate;

    const effectiveEndDate =
      FormData.period === "custom" && FormData.endDate
        ? dayjs(FormData.endDate).format("YYYY-MM-DD")
        : defaultEndDate;

    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetCustomerReportBy/${CompanyID}?customerAccountCode=${
          FormData.customerAccountCode
        }&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}&period=${
          FormData.period
        }&includeAllCustomers=${FormData.customerName === "all"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      console.log(response.data);
      setTransactions(response.data);
      setShowTable(true); // Show table after fetching data
    } catch (err) {
      console.error("Error fetching customer transactions:", err);
      setError("Failed to load transactions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Customer Report";
    fetchCustomer();
  }, []);

  const fetchCustomer = async () => {
    setCustomerLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}CustomerSupplier/GetCustomersBy/${CompanyID}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setListOfRecords(response.data.listofCustomers || []);
        setTotalRecords(response.data.totalRecords || 0);
        setCustomerLoading(false);
      } else {
        setCustomerLoading(false);
        setListOfRecords([]);
      }
    } catch (error) {
      setCustomerLoading(false);
      setListOfRecords([]);
    }
  };

  const handleCustomerChange = (value) => {
    if (value === "all") {
      setSelectedCustomer(null); // Clear selected customer
      CustomerForm.setFieldsValue({
        customerAccountCode: null,
      });
    } else {
      const customer = ListOfRecords.find(
        (customer) =>
          customer.businessName + " " + `(${customer.accountNo})` === value
      );
      setSelectedCustomer(customer);
      if (customer) {
        CustomerForm.setFieldsValue({
          customerAccountCode: customer.accountNo,
        });
      }
    }
  };

  const handleDateChange = (date, dateString) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleDueDateChange = (date, dateString) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  let runningBalance = 0; // Initialize running balance

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text) => `Sale ${text}`,
    },
    {
      title: "Ref No",
      dataIndex: "refNo",
      key: "refNo",
    },
    {
      title: "V. No",
      dataIndex: "voucherNo",
      key: "voucherNo",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (value) => (value ? value.toFixed(2) : "0.00"),
    },
    // Initialize running balance outside render

    {
      title: "Balance",
      dataIndex: "",
      key: "balance",
      render: (text, record, index) => {
        // Calculate the current record's balance
        const currentBalance = record.debit - record.credit;

        // Add the current record's balance to the running balance
        if (index === 0) {
          runningBalance = currentBalance; // For the first row
        } else {
          runningBalance += currentBalance; // Add to the running balance for subsequent rows
        }

        // Return the updated running balance
        return <>{runningBalance.toFixed(2)}</>;
      },
    },
  ];

  const summary = () => {
    let totalDebit = 0;
    let totalCredit = 0;

    transactions.forEach(({ debit, credit }) => {
      totalDebit += debit || 0;
      totalCredit += credit || 0;
    });

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={5}>Total</Table.Summary.Cell>
        <Table.Summary.Cell>{totalDebit.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalCredit.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>
          {/* Balance can be calculated if needed */}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  const printTable = () => {
    const isAllCustomersSelected = selectedCustomer === "All Customers";

    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css' />"
    );
    printWindow.document.write("</head><body style='text-align: center;'>");
    printWindow.document.write(
      "<h1 style='text-align: center;'>Customer Report</h1>"
    );

    // Conditionally render customer name if "All Customers" is NOT selected
    if (!isAllCustomersSelected) {
      printWindow.document.write(
        "<h3>Customer: " +
          (selectedCustomer?.businessName || "All Customers") +
          "</h3>"
      );
      printWindow.document.write(
        "<h3>Account Code: " + (selectedCustomer?.accountNo || "N/A") + "</h3>"
      );
    } else if (isAllCustomersSelected) {
      printWindow.document.write("<h3>All Customers</h3>");
    }

    if (startDate && endDate) {
      printWindow.document.write(
        "<h3>Date Range: " + startDate + " - " + endDate + "</h3>"
      );
    }

    printWindow.document.write(
      "<div style='display: flex; justify-content: center;'>"
    );
    printWindow.document.write(document.querySelector(".ant-table").outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <>
      <div className="sub-menu-wrap">
        <h5>Sales</h5>
        <SalesMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/customer/manage">
                <ArrowLeftIcon />
              </NavLink>
              Customer Report
            </h3>
          </div>
          <Form
            layout="vertical"
            size="medium"
            className="form-default"
            form={CustomerForm}
            onFinish={fetchCustomerTransactions}
          >
            <Row gutter={[24, 24]}>
              <Col span={6}>
                <Form.Item name="customerName" label="Customer">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Customer"
                    loading={CustomerLoading}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent={
                      CustomerLoading ? <Spin size="small" /> : null
                    }
                    onSelect={handleCustomerChange}
                  >
                    <Select.Option value="all">All Customers</Select.Option>
                    {ListOfRecords.map((customer) => (
                      <Select.Option
                        key={customer.accountNo}
                        value={
                          customer.businessName + ` (${customer.accountNo})`
                        }
                      >
                        {customer.businessName} ({customer.accountNo})
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="period"
                  label="Date Range"
                  onChange={(value) => setPeriod(value)}
                  initialValue="last60Days"
                >
                  <Select placeholder="Date Range">
                    <Select.Option value="all">All Dates</Select.Option>
                    <Select.Option value="custom">Custom</Select.Option>
                    <Select.Option value="today">Today</Select.Option>
                    <Select.Option value="week">This Week</Select.Option>
                    <Select.Option value="month">This Month</Select.Option>
                    <Select.Option value="last60Days">
                      Last 60 Days
                    </Select.Option>
                    <Select.Option value="year">This Year</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Form.Item
                name="customerAccountCode"
                label="Customer Account Code"
                hidden
              >
                <Input />
              </Form.Item>

              <Col span={6}>
                <Form.Item name="startDate" label="From Date">
                  <DatePicker
                    value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                    style={{ width: "100%" }}
                    onChange={handleDateChange}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item name="endDate" label="To Date">
                  <DatePicker
                    value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                    style={{ width: "100%" }}
                    onChange={handleDueDateChange}
                  />
                </Form.Item>

                <br />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="small"
                    style={{ marginLeft: "180px", marginBottom: "10px" }}
                    loading={loading}
                  >
                    Run Report
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {showTable && transactions.length > 0 && (
            <>
              <Button
                type="default"
                onClick={printTable}
                style={{ marginBottom: 16 }}
              >
                Print Report
              </Button>

              <Table
                columns={columns}
                dataSource={transactions}
                rowKey="id"
                summary={summary}
                pagination={false}
              />
            </>
          )}

          {showTable && transactions.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default CustomerReport;
