import {
  Form,
  Input,
  Select,
  Table,
  Button,
  Dropdown,
  Space,
  Menu,
  Popconfirm,
  message,
  Pagination,
  Divider,
  Radio,
  DatePicker,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Link, NavLink } from "react-router-dom";
import Config from "../../../Config";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { QueueListIcon } from "@heroicons/react/24/outline";
import PurchaseMenu from "./../PurchaseMenu";
const ExcelJS = require("exceljs");

function Payments() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const CompanyID = localStorage.getItem("CompanyID");
  const FYear = localStorage.getItem("DefaultFYear");

  const [loading, setLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [OrderBy, setOrderBy] = useState("");
  const [Name, setName] = useState("");
  const [AccountCode, setAccountCode] = useState("");
  const [Type, setType] = useState("");
  const [Date, setDate] = useState("");
  const [CustomerID, setCustomerID] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [OpenDate, setOpenDate] = useState("");

  const [form] = Form.useForm();
  const [formMain] = Form.useForm();

  const fetchSales = async () => {
    setLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}PaymentHead/GetBy/${CompanyID}?orderBy=${OrderBy}&supplierAccountCode=${AccountCode}&supplierName=${Name}&paymentType=${Type}&date=${Date}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setListOfRecords(response.data.listofPayments || []);
      } else {
        setListOfRecords([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setListOfRecords([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Supplier Payments";
    fetchSales();
  }, [OrderBy, AccountCode, Name, Type, Date]);

  const handleFilters = (formData) => {
    console.log("Form Data Submitted:", formData);
    setAccountCode(formData["supplierAccountCode"] || "");
    setName(formData["supplierName"] || "");
    setOrderBy(formData["OrderBy"] || "");
    setType(formData["paymentType"] || "");
    setDate(OpenDate);
    console.log("Type State after set:", formData["purchaseType"]);
    // fetchAccounts();
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "A/C No.",
      dataIndex: "supplierAccountCode",
      key: "supplierAccountCode",
    },

    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
    },

    {
      title: "Type",
      dataIndex: "purchaseType",
      key: "purchaseType",
    },
    {
      title: "Voucher No.",
      dataIndex: "",
      key: "voucherNo",
      render: (text, record) => (
        <>
          <>
            <NavLink
              className={"primary"}
              to={
                record.purchaseType === "Payment"
                  ? `/purchases/purchase-payments/edit-supplier-payment/${record.voucherNo}`
                  : `/purchases/purchase-payments/edit-supplier-receipt/${record.voucherNo}`
              }
            >
              {record.voucherNo}
            </NavLink>
          </>
        </>
      ),
    },
    {
      title: "Ref No.",
      dataIndex: "refNO",
      key: "refNo",
    },
    {
      title: "Amount",
      dataIndex: "",
      key: "amount",
      render: (text, record) => (
        <>
          {record.purchaseType === "Payment" ||
            record.purchaseType === "Return Receipt" ? (
            <>{record.amount}</>
          ) : (
            <>{-record.amount}</>
          )}
        </>
      ),
    },
    {
      title: "Total",
      dataIndex: "",
      key: "totalPayment",
      render: (text, record) => (
        <>
          {record.purchaseType === "Payment" ||
            record.purchaseType === "Return Receipt" ? (
            <>{record.amount}</>
          ) : (
            <>{-record.amount}</>
          )}
        </>
      ),
    },

    {
      title: "Balance",
      dataIndex: "unAllocatedBalance",
      key: "unAllocatedBalance",
    },
    {
      title: "Status",
      dataIndex: "",
      key: "inComplete",
      render: (text, record) => (
        <>{record.inComplete === true ? <>InComplete</> : <>Complete</>}</>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) => (
        <div className="table-actions">
          <NavLink
            className={"primary"}
            to={
              record.purchaseType === "Payment"
                ? `/purchases/purchase-payments/edit-supplier-payment/${record.voucherNo}`
                : `/purchases/purchase-payments/edit-supplier-receipt/${record.voucherNo}`
            }
          >
            <EditOutlined />
          </NavLink>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this account?"
            onConfirm={
              record.purchaseType === "Payment" || "Return Receipt"
                ? (e) => deletePayment(record.id)
                : (e) => deleteReceipt(record.id)
            }
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const deleteReceipt = async (sno) => {
    setLoading(true);
    try {
      const accountToUpdate = ListOfRecords.find((u) => u.id === sno);
      console.log(accountToUpdate);
      if (!accountToUpdate) {
        message.error("Payment not found!");
        return;
      }

      const name = accountToUpdate.supplierName.match(/^[^\(]+/)[0].trim();
      const code = accountToUpdate.supplierAccountCode;

      const SupplierData = await axios.get(
        `${Config.base_url}CustomerSupplier/GetSuppliersBy/${CompanyID}?businessName=${name}&accountCode=${code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      console.log(SupplierData.data.listofSuppliers[0]);

      const deleteSupplier = SupplierData.data.listofSuppliers[0];
      if (!accountToUpdate) {
        message.error("Payment not found!");
        return;
      }

      const updatedAccount = {
        ...accountToUpdate,
        isActive: false,
        isDeleted: true,
      };

      const response = await axios.patch(
        `${Config.base_url}PaymentHead/UpdateRecord/${sno}`,
        updatedAccount,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code == 1) {
        const updateSupplierData = {
          ...deleteSupplier,
          isCustomer: false,
          supplierOpeningBalance:
            deleteSupplier.supplierOpeningBalance - accountToUpdate.amount,
        };
        await axios.patch(
          Config.base_url + `CustomerSupplier/UpdateRecord/${deleteSupplier.id}`,
          updateSupplierData,
          {
            headers: {
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );
        message.success("Payment deleted Successfully");
      }
    } catch (error) {
      console.log(error);
      message.error("Error in Deleting receipt");
    }
    fetchSales();
  };

  const deletePayment = async (sno) => {
    setLoading(true);
    try {
      const accountToUpdate = ListOfRecords.find((u) => u.id === sno);
      console.log(accountToUpdate);
      if (!accountToUpdate) {
        message.error("Payment not found!");
        return;
      }

      const name = accountToUpdate.supplierName.match(/^[^\(]+/)[0].trim();
      const code = accountToUpdate.supplierAccountCode;

      const SupplierData = await axios.get(
        `${Config.base_url}CustomerSupplier/GetSuppliersBy/${CompanyID}?businessName=${name}&accountCode=${code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      console.log(SupplierData.data.listofSuppliers[0]);

      const deleteSupplier = SupplierData.data.listofSuppliers[0];
      if (!accountToUpdate) {
        message.error("Payment not found!");
        return;
      }

      const updatedAccount = {
        ...accountToUpdate,
        isActive: false,
        isDeleted: true,
      };

      const response = await axios.patch(
        `${Config.base_url}PaymentHead/UpdateRecord/${sno}`,
        updatedAccount,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );
      if (response.data.status_code == 1) {
        const updateSupplierData = {
          ...deleteSupplier,
          isCustomer: false,
          supplierOpeningBalance:
            deleteSupplier.supplierOpeningBalance + accountToUpdate.amount,
        };
        await axios.patch(
          Config.base_url + `CustomerSupplier/UpdateRecord/${deleteSupplier.id}`,
          updateSupplierData,
          {
            headers: {
              Authorization: `Bearer ${AccessKey}`,
            },
          }
        );
        message.success("Payment deleted Successfully");
      }
    } catch (error) {
      console.log(error);
      message.error("Error in Deleting receipt");
    }
    fetchSales();
  };

  const handleDateChange = (e, value) => {
    setOpenDate(value);
  };

  const onReset = () => {
    form.resetFields();
    setType("");
    setAccountCode("");
    setName("");
    setDate("");
    setOrderBy("");
    setOpenDate("");
  };

  const items = [
    {
      key: "1",
      label: (
        <Link to={`/purchases/purchase-payments/add-supplier-payment`}>
          Payment (VP)
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={`/purchases/purchase-payments/add-supplier-receipt`}>
          Receipt (VCR)
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to={`/purchases/purchase-payments/add-return-supplier-payment`}>
          Return Payment (RVP)
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to={`/purchases/purchase-payments/add-return-supplier-receipt`}>
          Return Receipt (RVCR)
        </Link>
      ),
    },
  ];

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sales");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Sr#", key: "sr", width: 10 },
      { header: "Date", key: "date", width: 20 },
      { header: "Account Number", key: "supplierAccountCode", width: 30 },
      { header: "Supplier Name", key: "supplierName", width: 30 },
      { header: "Type", key: "purchaseType", width: 20 },
      { header: "Ref No.", key: "refNo", width: 20 },
      { header: "Amount", key: "amount", width: 20 },
      { header: "Total", key: "totalPayment", width: 15 },
      { header: "Balance", key: "unAllocatedBalance", width: 15 },
    ];

    // Add rows to the sheet
    ListOfRecords.forEach((payments, index) => {
      sheet.addRow({
        sr: index + 1,
        date: payments.date,
        supplierAccountCode: payments.supplierAccountCode,
        supplierName: payments.supplierName,
        purchaseType: payments.purchaseType,
        refNo: payments.refNo,
        amount: payments.amount,
        totalPayment: payments.totalPayment,
        unAllocatedBalance: payments.unAllocatedBalance,
      });
    });

    const now = new window.Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" })
      .replace(/[^0-9]/g, "");

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `PaymentsList_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div className="sub-menu-wrap">
        <h5>Payments</h5>
        {/* <PurchaseMenu /> */}
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Supplier Payments</h3>
            <div className="header-actions">
              <Button
                type="dashed"
                onClick={handleExport}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomLeft"
                arrow
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  New
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="filters-wrap">
            <Form onFinish={handleFilters} form={form}>
              <Form.Item name="OrderBy">
                <Select placeholder="Order By" style={{ width: 120 }}>
                  <Select.Option value="supplieraccountcode">
                    A/C Code
                  </Select.Option>
                  <Select.Option value="suppliername">
                    Supplier Name
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="paymentType">
                <Select placeholder="Type" style={{ width: 120 }}>
                  <Select.Option value="">All Types</Select.Option>
                  <Select.Option value="Payment">Payment</Select.Option>
                  <Select.Option value="Receipt">Receipt</Select.Option>
                  <Select.Option value="Return Payment">
                    Return Payment
                  </Select.Option>
                  <Select.Option value="Return Receipt">
                    Return Receipt
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="supplierName">
                <Input placeholder="Supplier Name" />
              </Form.Item>
              <Form.Item name="supplierAccountCode">
                <Input placeholder="A / C No" />
              </Form.Item>
              <Form.Item name="date">
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={handleDateChange}
                  placeholder="Date"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Button htmlType="submit" type="primary">
                Filter
              </Button>
              <Button htmlType="button" onClick={onReset} type="link">
                Reset
              </Button>
            </Form>
          </div>

          <Table
            columns={columns}
            dataSource={ListOfRecords}
            size="small"
            loading={loading}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
}

export default Payments;
