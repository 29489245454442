import { Form, Input, Select, Table, Button, Popconfirm, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import AccountsMenu from "./AccountsMenu";
import Config from "../../Config";
import axios from "axios";
import SettingMenu from "../Setting/SettingMenu";
const ExcelJS = require("exceljs");

function ManageAccounts(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [loading, setLoading] = useState(false);
  const [ListOfAccounts, setListOfAccounts] = useState([]);
  const [OrderBy, setOrderBy] = useState("");
  const [AccountID, setAccountID] = useState("");
  const [AccountCode, setAccountCode] = useState("");
  const [Level, setLevel] = useState(0);

  const [form] = Form.useForm();

  const fetchAccounts = async () => {
    setLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}AccountMain/GetBy/${CompanyID}?orderBy=${OrderBy}&accountCode=${AccountCode}&accountName=${AccountID}&level=${Level}`,
      headers: {
        Authorization: `Bearer ${AccessKey}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setListOfAccounts(response.data.listofAccounts || []);
      } else {
        setListOfAccounts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setListOfAccounts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Manage Accounts";
    fetchAccounts();
  }, [OrderBy, AccountCode, AccountID, Level]);

  const handleFilters = (formData) => {
    console.log("Form Submitted with:", formData);
    setLevel(formData["iLevel"] || 0);
    setAccountID(formData["accountDescription"] || "");
    setOrderBy(formData["OrderBy"] || "");
    setAccountCode(formData["accountCode"] || "");
    // fetchAccounts();
  };

  const deleteAccount = async (sno) => {
    try {
      const accountToUpdate = ListOfAccounts.find((u) => u.id === sno);
      if (!accountToUpdate) {
        alert("Account not found!");
        return;
      }

      const updatedAccount = {
        ...accountToUpdate,
        isActive: false,
        isDeleted: true,
      };

      await axios.patch(
        `${Config.base_url}AccountMain/DeleteAccount/${sno}`,
        updatedAccount,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AccessKey}`,
          },
        }
      );

      setListOfAccounts((prev) => prev.filter((account) => account.id !== sno));
      message.success("Account deleted successfully.");
      fetchAccounts(); // Refresh the list
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const onReset = () => {
    form.resetFields();
    setLevel("0");
    setAccountCode("");
    setAccountID("");
    setOrderBy("");
    // fetchAccounts(); // Fetch all data again without any filters
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Account Name",
      dataIndex: "accountDescription",
      key: "accountDescription",
    },
    {
      title: "Account Code",
      dataIndex: "accountCode",
      key: "accountCode",
    },
    {
      title: "Level",
      dataIndex: "iLevel",
      key: "iLevel",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) =>
        record.iLevel > 1 && (
          <div className="table-actions">
            <NavLink
              className={"primary"}
              to={`/accounts/edit-account/${record.id}`}
            >
              <EditOutlined />
            </NavLink>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this account?"
              onConfirm={() => deleteAccount(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </div>
        ),
    },
  ];

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Accounts");

    // Set column headers and their widths
    sheet.columns = [
      { header: "Account Name", key: "accountDescription", width: 30 },
      { header: "Account Code", key: "accountCode", width: 15 },
      { header: "Level", key: "iLevel", width: 5 },
    ];

    // Add rows to the sheet
    ListOfAccounts.forEach((account, index) => {
      sheet.addRow({
        sr: index + 1,
        accountDescription: account.accountDescription,
        accountCode: account.accountCode,
        iLevel: account.iLevel,
      });
    });

    const now = new Date();
    const dateString = now
      .toLocaleString("sv-SE", { timeZoneName: "short" }) // Format: YYYY-MM-DD HH:mm:ss
      .replace(/[^0-9]/g, ""); // Remove special characters like : and space

    // Generate the Excel file and prompt the user to download it
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `AccountsList_${dateString}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div className="sub-menu-wrap">
        <h5>Settings</h5>
        <SettingMenu />
      </div>
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Manage Accounts</h3>
            <div className="header-actions">
              <NavLink to="/accounts/import">
                <Button type="dashed" icon={<DownloadOutlined />}>
                  Import
                </Button>
              </NavLink>
              <Button
                type="dashed"
                onClick={handleExport}
                icon={<UploadOutlined />}
              >
                Export
              </Button>
              <NavLink to="/accounts/add-account">
                <Button type="primary" icon={<PlusOutlined />}>
                  New
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form onFinish={handleFilters} form={form} layout="vertical">
              <Form.Item name="OrderBy">
                <Select placeholder="Order By" style={{ width: 120 }}>
                  <Select.Option value="accountCode">
                    Account Code
                  </Select.Option>
                  <Select.Option value="accountDescription">
                    Account Name
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="iLevel">
                <Select placeholder="All Level" style={{ width: 120 }}>
                  <Select.Option value="0">All Levels</Select.Option>
                  <Select.Option value="1">Level 1</Select.Option>
                  <Select.Option value="2">Level 2</Select.Option>
                  <Select.Option value="3">Level 3</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="accountDescription">
                <Input placeholder="Account Name" />
              </Form.Item>
              <Form.Item name="accountCode">
                <Input placeholder="Account No" />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Filter
              </Button>
              <Button onClick={onReset} type="link">
                Reset
              </Button>
            </Form>
          </div>

          <Table
            columns={columns}
            dataSource={ListOfAccounts}
            size="small"
            loading={loading}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
}

export default ManageAccounts;
