import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./assets/css/style.css";
import Login from "./components/Onboarding/Login";
import Signup from "./components/Onboarding/Signup";
import Welcome from "./components/Onboarding/Welcome";
import RegisterCompany from "./components/Onboarding/RegisterCompany";
import VerifyEmail from "./components/Onboarding/VerifyEmail";
import Dashboard from "./components/Dashboard/Dashboard";
import Main from "./components/Layout/Main";
import SelectCompany from "./components/Onboarding/SelectCompany";
import { ConfigProvider, theme } from "antd";
import ChangeCompany from "./components/Company/ChangeCompany";
import SendInvitation from "./components/Setting/SendInvitation";
import ForgetPassword from "./components/Onboarding/ForgetPassword";
import SettingDashboard from "./components/Setting/SettingDashboard";
import ChangePassword from "./components/profile/ChangePassword";
import UpdateProfile from "./components/profile/UpdateProfile";
import AddAccount from "./components/Accounts/AddAccount";
import ManageAccounts from "./components/Accounts/ManageAccounts";
import AddVouchers from "./components/Accounts/Voucher/AddVouchers";
import ManageEmployees from "./components/Payroll/Employee/ManageEmployees";
import ManageVouchers from "./components/Accounts/Voucher/ManageVouchers";
import TrialBalanceLevelWise from "./components/Accounts/TrialBalanceLevelWise";
import TrialBalanceHeadWise from "./components/Accounts/TrialBalanceHeadWise";
import BudgetHeadWise from "./components/Accounts/BudgetHeadWise";
import ReceiptPaymentAccount from "./components/Accounts/ReceiptPaymentAccount";
import OpeningBalances from "./components/Accounts/OpeningBalances";
import AddEmployee from "./components/Payroll/Employee/AddEmployee";
import Designation from "./components/Payroll/Employee/Designation";
import SalaryType from "./components/Payroll/Salary/SalaryType";
import BanksalaryList from "./components/Payroll/Salary/BankSalaryList";
import Createsalary from "./components/Payroll/Salary/CreateSalary";
import IncreaseDecreaseSalary from "./components/Payroll/Salary/IncreaseDecreaseSalary";
import Viewreports from "./components/Payroll/Reports/ViewReports";
import Zeroreports from "./components/Payroll/Reports/ZeroReports";
import SummarySheet from "./components/Payroll/Reports/SummarySheet";
import AddArrears from "./components/Payroll/ArrearLeave/AddArrears";
import ManageLeaves from "./components/Payroll/ArrearLeave/ManageLeaves";
import ManageLoanDeduction from "./components/Payroll/Deductions/ManageLoanDeduction";
import ManageOtherDeduction from "./components/Payroll/Deductions/ManageOtherDeduction";
import ImportAccounts from "./components/Accounts/ImportAccounts";
import EditVouchers from "./components/Accounts/Voucher/EditVouchers";
import EditAccount from "./components/Accounts/EditAccount";
import ManageCustomer from "./components/Customer/ManageCustomer";
import DropdownTest from "./components/Common/CustomerFieldsDropdown";
import ImportCustomer from "./components/Customer/ImportCustomer";
import ManageSupplier from "./components/Suppliers/ManageSupplier";
import ImportSupplier from "./components/Suppliers/ImportSupplier";
import ManageBill from "./components/Bill/ManageBill";
import ImportBill from "./components/Bill/ImportBill";
import SupplierBill from "./components/Suppliers/Bill/SupplierBill";
import ManageBank from "./components/Bank/ManageBank";
import ImportBank from "./components/Bank/ImportBank";
import AddBankPayments from "./components/Bank/AddBankPayments";
import AddWHTPayments from "./components/Bank/AddWHTPayments";
import AddBankReceipts from "./components/Bank/AddBankReceipts";
import ManageReceipts from "./components/Bank/ManageReceipts";
import AddTransfers from "./components/Bank/AddTransfers";
import ManageTransfers from "./components/Bank/ManageTransfers";
import ComponentTestPage from "./ComponentTestPage";
import ReconcileStatementBalance from "./components/Bank/ReconcileStatementBalance";
import ManageReconcile from "./components/Bank/ManageReconcile";
import SalesInvoices from "./components/Sales/SalesInvoices/SalesInvoices";
import SaleReceipts from "./components/Sales/SalesReceipt/SaleReceipts";
import AddSalesInvoice from "./components/Sales/SalesInvoices/AddSalesInvoice";
import AddCreditNote from "./components/Sales/SalesInvoices/AddCreditNote";
import BatchInvoice from "./components/Sales/SalesInvoices/BatchInvoice";
import BulkInvoicing from "./components/Sales/SalesInvoices/BulkInvoicing";
import ResetPassword from "./components/Onboarding/ResetPassword";
import axios from "axios";
import Config from "./Config";
import EditCompany from "./components/Company/EditCompany";
import EditBalance from "./components/Accounts/EditBalance";
import InviteSignup from "./components/Onboarding/InviteSignup";
import VerifyInvite from "./components/Onboarding/VerifyInvite";
import ManageUsers from "./components/Setting/ManageUsers";
import EditUser from "./components/Setting/EditUser";
import ManageInvitations from "./components/Setting/ManageInvitations";
import ProductionMenu from "./components/Production/ProductionMenu";
import ManageProducts from "./components/Production/ManageProducts";
import AddStockProducts from "./components/Production/AddStockProducts";
import AddNonStockProducts from "./components/Production/AddNonStockProducts";
import EditStockProducts from "./components/Production/EditStockProduct";
import EditNonStockProducts from "./components/Production/EditNonStockProduct";
import ImportProducts from "./components/Production/ImportProducts";
import EditSaleInvoice from "./components/Sales/SalesInvoices/EditSaleInvoice";
import EditCreditNote from "./components/Sales/SalesInvoices/EditCreditNote";
import AddSupplierBill from "./components/Purchases/AddSupplierBill";
import Bills from "./components/Purchases/SupplierBills";
import AddSalesReceipt from "./components/Sales/SalesReceipt/AddSaleReceipt";
import AddSalesPayment from "./components/Sales/SalesReceipt/AddSalePayment";
import EditReceipt from "./components/Sales/SalesReceipt/EditReceipt";
import EditCreditPayment from "./components/Sales/SalesReceipt/EditCreditPayment";
import AddReturnReceipt from "./components/Sales/SalesReceipt/AddReturnReciept";
import AddCreditBill from "./components/Purchases/AddCreditBill";
import BatchBill from "./components/Purchases/BatchBill";
import AddReturnPayment from "./components/Sales/SalesReceipt/AddReturnPayment";
import EditReturnReceipt from "./components/Sales/SalesReceipt/EditReturnReceipt";
import EditReturnPayment from "./components/Sales/SalesReceipt/EditReturnPayment";
import EditSupplierBill from "./components/Purchases/EditSupplierBill";
import EditSupplierPayment from "./components/Purchases/Payments/EditSupplierPayment";
import Payments from "./components/Purchases/Payments/Payments";
import AddSupplierPayment from "./components/Purchases/Payments/AddSupplierPayment";
import EditCreditBill from "./components/Purchases/EditCreditBill";
import AddSupplierReceipt from "./components/Purchases/Payments/AddSupplierReceipt";
import AddReturnSupplierPayment from "./components/Purchases/Payments/AddReturnSupplierPayment";
import EditSupplierReceipt from "./components/Purchases/Payments/EditSupplierReceipt";
import ResetCompany from "./components/Setting/ResetCompany";
import CustomerReport from "./components/Customer/CustomerReport";
import AccountBalance from "./components/Bank/AccountBalance";
import SupplierReport from "./components/Suppliers/SupplierReport";
import ProductReport from "./components/Production/ProductReport";
import EditBankReceipts from "./components/Bank/EditBankReceipts";
import EditBankPayments from "./components/Bank/EditBankPayments";
import EditWHTPayments from "./components/Bank/EditWHTPayments";
import AddReturnSupplierReceipt from "./components/Purchases/Payments/AddReturnSupplierReceipt";
import BankReports from "./components/Bank/BankReports";

function Index() {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const pictureId = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (pictureId) {
      axios
        .get(Config.base_url + `Pictures/${pictureId}`, {
          headers: {
            Authorization: `Bearer ${AccessKey}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          setImageSrc(url);
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        });
    }
  }, [pictureId]);

  return (
    <ConfigProvider
      theme={{
        // algorithm: darkAlgorithm,
        //type: 'dark',

        components: {
          Button: {
            primaryColor: "#fff",
            fontWeight: "600",
            contentFontSize: 16,
            defaultBorderColor: "#2046CF",
            defaultColor: "#2046CF",
          },
          Form: {
            labelColor: "#68757D",
            labelFontSize: 14,
          },
        },

        token: {
          // Seed Token
          colorPrimary: "#2046CF",
          //borderRadius: 12,

          // Alias Token
          //colorBgContainer: '#324F94',
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/invite-signup/:id" element={<InviteSignup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/verify-invite/:id" element={<VerifyInvite />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/company" element={<SelectCompany />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/company-register" element={<RegisterCompany />} />

          <Route path="/" element={<Main image={imageSrc} />}>
            <Route path="/test-page" element={<ComponentTestPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/register-company" element={<RegisterCompany />} />
            <Route path="/change-company" element={<ChangeCompany />} />
            <Route path="/edit-company" element={<EditCompany />} />
            <Route
              path="/setting/send-invitation"
              element={<SendInvitation />}
            />
            <Route path="/setting/manage-users" element={<ManageUsers />} />
            <Route
              path="/setting/manage-invites"
              element={<ManageInvitations />}
            />
            <Route path="/setting/edit-user/:id" element={<EditUser />} />
            <Route path="/setting" element={<SettingDashboard />} />
            <Route path="/setting/reset-company" element={<ResetCompany />} />

            {/* Production */}
            <Route path="/production" element={<ProductionMenu />} />
            <Route path="/products/manage" element={<ManageProducts />} />
            <Route
              path="/production/add-stock-products"
              element={<AddStockProducts />}
            />

            <Route
              path="/production/add-non-stock-products"
              element={<AddNonStockProducts />}
            />
            <Route
              path="/product/edit-stock-products/:id"
              element={<EditStockProducts />}
            />
            <Route
              path="/product/edit-Nonstock-products/:id"
              element={<EditNonStockProducts />}
            />
            <Route path="/products/import" element={<ImportProducts />} />
            <Route path="/products/report" element={<ProductReport />} />

            {/* accounts */}
            <Route path="/accounts/add-account" element={<AddAccount />} />
            <Route
              path="/accounts/edit-account/:id"
              element={<EditAccount />}
            />
            <Route
              path="/accounts/edit-balance/:id"
              element={<EditBalance />}
            />
            <Route path="/accounts/manage" element={<ManageAccounts />} />
            <Route
              path="/accounts/opening-balances"
              element={<OpeningBalances />}
            />
            <Route
              path="/accounts/trial-balance-level-wise"
              element={<TrialBalanceLevelWise />}
            />
            <Route
              path="/accounts/trial-balance-Head-wise"
              element={<TrialBalanceHeadWise />}
            />
            <Route
              path="/accounts/Budget-Head-wise"
              element={<BudgetHeadWise />}
            />
            <Route
              path="/accounts/Receipt-Payment-Account"
              element={<ReceiptPaymentAccount />}
            />
            <Route path="/accounts/import" element={<ImportAccounts />} />

            <Route
              path="/profile"
              element={<UpdateProfile image={imageSrc} />}
            />
            <Route path="/change-password" element={<ChangePassword />} />

            {/* Bank */}

            <Route path="/bank/import" element={<ImportBank />} />
            <Route path="/bank/report" element={<BankReports />} />
            <Route path="/bank/account-balance" element={<AccountBalance />} />

            <Route path="/bank/manage" element={<ManageBank />} />
            <Route
              path="/bank/add-bank-payment"
              element={<AddBankPayments />}
            />
            <Route
              path="/bank/edit-bank-payment/:id"
              element={<EditBankPayments />}
            />
            <Route path="/bank/wht-bank-payment" element={<AddWHTPayments />} />
            <Route
              path="/bank/edit-wht-bank-payment/:id"
              element={<EditWHTPayments />}
            />

            <Route path="/bank/receipts" element={<ManageReceipts />} />
            <Route
              path="/bank/add-bank-receipts"
              element={<AddBankReceipts />}
            />
            <Route
              path="/bank/edit-bank-receipts/:id"
              element={<EditBankReceipts />}
            />

            <Route path="/bank/transfers" element={<ManageTransfers />} />
            <Route path="/bank/add-transfers" element={<AddTransfers />} />

            <Route path="/bank/reconcile" element={<ManageReconcile />} />
            <Route
              path="/bank/reconcile-statement-balance"
              element={<ReconcileStatementBalance />}
            />

            {/* sales */}

            <Route path="/sales/sales-invoices" element={<SalesInvoices />} />
            <Route path="/sales/sales-receipts" element={<SaleReceipts />} />

            <Route
              path="/sales/sales-invoices/add-sales-invoices"
              element={<AddSalesInvoice />}
            />
            <Route
              path="/sales/sales-receipts/add-sales-receipts"
              element={<AddSalesReceipt />}
            />
            <Route
              path="/sales/sales-invoices/add-credit-note"
              element={<AddCreditNote />}
            />
            <Route
              path="/sales/sales-receipts/add-sales-payment"
              element={<AddSalesPayment />}
            />
            <Route
              path="/sales/sales-receipts/add-return-receipt"
              element={<AddReturnReceipt />}
            />
            <Route
              path="/sales/sales-receipts/add-return-payment"
              element={<AddReturnPayment />}
            />
            <Route
              path="/sales/sales-invoices/batch-invoice"
              element={<BatchInvoice />}
            />
            <Route
              path="/sales/sales-invoices/bulk-invoicing"
              element={<BulkInvoicing />}
            />
            <Route
              path="/sales/sales-invoices/edit-sales-invoices/:id"
              element={<EditSaleInvoice />}
            />
            <Route
              path="/sales/sales-receipts/edit-sales-receipts/:id"
              element={<EditReceipt />}
            />
            <Route
              path="/sales/sales-receipts/edit-return-receipts/:id"
              element={<EditReturnReceipt />}
            />
            <Route
              path="/sales/sales-invoices/edit-credit-note/:id"
              element={<EditCreditNote />}
            />
            <Route
              path="/sales/sales-receipts/edit-credit-payments/:id"
              element={<EditCreditPayment />}
            />
            <Route
              path="/sales/sales-receipts/edit-return-payment/:id"
              element={<EditReturnPayment />}
            />

            {/* Purchases */}
            <Route path="/purchases/purchase-bills" element={<Bills />} />
            <Route path="/purchases/purchase-payments" element={<Payments />} />

            <Route
              path="/purchases/purchase-bills/add-purchase-bills"
              element={<AddSupplierBill />}
            />
            <Route
              path="/purchases/purchase-bills/add-credit-bill"
              element={<AddCreditBill />}
            />
            <Route
              path="/purchases/purchase-bills/batch-bill"
              element={<BatchBill />}
            />
            <Route
              path="/purchases/purchase-bills/edit-purchase-bill/:id"
              element={<EditSupplierBill />}
            />
            <Route
              path="/purchases/purchase-bills/edit-credit-bill/:id"
              element={<EditCreditBill />}
            />

            <Route
              path="/purchases/purchase-payments/add-supplier-payment"
              element={<AddSupplierPayment />}
            />
            <Route
              path="/purchases/purchase-payments/add-supplier-receipt"
              element={<AddSupplierReceipt />}
            />
            <Route
              path="/purchases/purchase-payments/add-return-supplier-payment"
              element={<AddReturnSupplierPayment />}
            />
            <Route
              path="/purchases/purchase-payments/add-return-supplier-receipt"
              element={<AddReturnSupplierReceipt />}
            />
            <Route
              path="/purchases/purchase-payments/edit-supplier-payment/:id"
              element={<EditSupplierPayment />}
            />
            <Route
              path="/purchases/purchase-payments/edit-supplier-receipt/:id"
              element={<EditSupplierReceipt />}
            />

            {/*  Voucher */}

            <Route path="/vouchers" element={<ManageVouchers />} />
            <Route path="/vouchers/add" element={<AddVouchers />} />
            <Route path="/vouchers/edit/:id" element={<EditVouchers />} />

            {/*  Customer */}

            <Route path="/customer/manage" element={<ManageCustomer />} />
            <Route path="/customer/report" element={<CustomerReport />} />
            <Route path="/customer/import" element={<ImportCustomer />} />

            {/* Supplier */}

            <Route path="/supplier/manage" element={<ManageSupplier />} />
            <Route path="/supplier/report" element={<SupplierReport />} />
            <Route path="/supplier/import" element={<ImportSupplier />} />

            {/* Bill */}

            <Route path="/bill/manage" element={<ManageBill />} />
            <Route path="/bill/import" element={<ImportBill />} />

            <Route
              path="/supplier/supplier-bill/add-supplier-bill"
              element={<SupplierBill />}
            />
            <Route
              path="/supplier/supplier-bill/add-supplier-bill"
              element={<SupplierBill />}
            />

            <Route
              path="/supplier/supplier-bill/add-supplier-bill"
              element={<SupplierBill />}
            />

            {/* Payroll */}

            <Route
              path="/payroll/employee/manage"
              element={<ManageEmployees />}
            />
            <Route path="/payroll/employee/add" element={<AddEmployee />} />
            <Route
              path="/payroll/employee/designations"
              element={<Designation />}
            />
            <Route
              path="/payroll/salary/salary-type"
              element={<SalaryType />}
            />
            <Route
              path="/payroll/salary/bank-salary-list"
              element={<BanksalaryList />}
            />
            <Route
              path="/payroll/salary/create-salary"
              element={<Createsalary />}
            />
            <Route
              path="/payroll/salary/increase/decrease-salary"
              element={<IncreaseDecreaseSalary />}
            />
            <Route
              path="/payroll/reports/view-reports"
              element={<Viewreports />}
            />
            <Route
              path="/payroll/reports/zero-reports"
              element={<Zeroreports />}
            />
            <Route
              path="/payroll/reports/summary-sheet"
              element={<SummarySheet />}
            />
            <Route
              path="/payroll/ArrearLeave/AddArrears"
              element={<AddArrears />}
            />
            <Route
              path="/payroll/ArrearLeave/mange-leave"
              element={<ManageLeaves />}
            />
            <Route
              path="/payroll/Deductions/manage-loan-deduction"
              element={<ManageLoanDeduction />}
            />
            <Route
              path="/payroll/Deductions/manage-other-deduction"
              element={<ManageOtherDeduction />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
