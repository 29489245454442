import React, { useEffect, useState } from "react";

import Logo from "../../assets/images/logo/white-v.svg";
import { NavLink } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Button, Skeleton, Row, Col } from "antd";
import Config from "../../Config";
import axios from "axios";

function ChangeCompany(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const Email = localStorage.getItem("Email_Address");
  const [ListOfCompanies, setListOfCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      try {

        const response = await axios.get(Config.base_url + `Account/GetCompaniesByEmail/${UserID}`, {
          headers: {
            Authorization: `Bearer ${AccessKey}`
          }
        })
        // console.log(response.data)

        const extractCompanyInfo = (data) => {
          return data.map(item => item.companyInfo);
        };

        const list = extractCompanyInfo(response.data);

        console.log(list);
        console.log(list.length);
        if (list) {
          setLoading(false)
          // console.log(list.length);
          if (list.length > 1) {
            setListOfCompanies(list);
            // console.log(response.data.listofCompanies);
            setLoading(false);
          } else if (list.length === 0) {
            navigate("/company-register");
          } else {
            const first = list[0];
            localStorage.setItem("CompanyCode", first.companyCode);
            localStorage.setItem("CompanyID", first.id);
            localStorage.setItem("CompanyName", first.name);
            localStorage.setItem("CompanyAddress", first.address);
            navigate("/dashboard");
          }
        }
        else {
          setLoading(false)
        }
      }
      catch (error) {
        console.error(error)
        if (error.response && error.response.data && error.response.data.status_code == 0) {
          navigate("/company-register")
        }
        setLoading(false)
      }
    }
    fetchRoles()
  }, [UserID, AccessKey, navigate])

  const handleCompany = (item) => {
    localStorage.setItem("CompanyCode", item.companyCode);
    localStorage.setItem("CompanyID", item.id);
    localStorage.setItem("CompanyName", item.name);
    localStorage.setItem("CompanyAddress", item.address);
    navigate("/dashboard");
  };

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content">
          <Row justify="center">
            <Col xs={24} md={12}>
              <h4>Change Company</h4>
              {loading ? (
                <Skeleton active />
              ) : (
                <ul className="list-actions">
                  {ListOfCompanies.map((item, index) => (
                    <li key={index}>
                      <a href="#" onClick={(e) => handleCompany(item)}>
                        <span>{item.name}</span>
                        <ChevronRightIcon />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ChangeCompany;
