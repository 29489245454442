'use client'
const host = window.location.hostname;

let base_url;
let path;
let img_path;
let imge;
let assets;
let root;
let admin_path;
let path_realestate;


if (host == 'localhost' || host == 'www.hisaaber.com') {

  // base_url = "http://localhost:52476/api/";
  // path = "http://localhost:52476/api/";
  // img_path = "http://localhost:52476/Personal/MemberImages/";
  // assets = "http://localhost:52476/assets/";
  // root = "http://localhost:52476/";

  base_url = "https://localhost:7143/api/";
  path = "https://localhost:7143/api/";
  // img_path = "https://api.hisaaber.com/Personal/MemberImages/";
  // assets = "https://api.hisaaber.com/assets/";
  // root = "https://api.hisaaber.com/api/";

}
else {
  base_url = "https://new.hisaaber.com/api/";
  path = "https://new.hisaaber.com/api/";
  img_path = "https://new.hisaaber.com/Personal/MemberImages/";
  assets = "https://new.hisaaber.com/assets/";
  root = "https://new.hisaaber.com/api/";
}


const Config = {
  base_url,
  path,
  img_path,
  imge,
  assets,
  root,
  admin_path,
  path_realestate,
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
};


export default Config;