import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  Table,
  Empty,
  Checkbox,
  Menu,
  Dropdown,
  Tooltip,
} from "antd";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ProductionMenu from "./ProductionMenu";
import axios from "axios";
import Config from "../../Config";
import dayjs from "dayjs";
import { FilterFilled } from "@ant-design/icons";

const ProductReport = () => {
  const navigate = useNavigate();
  const AccessKey = localStorage.getItem("AccessKey");
  const CompanyID = localStorage.getItem("CompanyID");

  const [ProductForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ProductLoading, setProductLoading] = useState(false);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState("all");
  const [customerAccountCode, setCustomerAccountCode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTable, setShowTable] = useState(false); // New state variable
  const defaultStartDate = dayjs().format("YYYY-MM-DD");
  const defaultEndDate = dayjs().format("YYYY-MM-DD");

  const fetchProductTransactions = async (FormData) => {
    setLoading(true);
    setError(null);

    const effectiveStartDate =
      FormData.period === "custom" && FormData.startDate
        ? dayjs(FormData.startDate).format("YYYY-MM-DD")
        : defaultStartDate;

    const effectiveEndDate =
      FormData.period === "custom" && FormData.endDate
        ? dayjs(FormData.endDate).format("YYYY-MM-DD")
        : defaultEndDate;

    try {
      const api_config = {
        method: "get",
        url: `${
          Config.base_url
        }Reports/GetProductReportBy/${CompanyID}?productName=${
          FormData.product || ""
        }&startDate=${effectiveStartDate}&endDate=${effectiveEndDate}&period=${
          FormData.period
        }&includeAllProducts=${FormData.product === "all"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessKey}`,
        },
      };
      const response = await axios(api_config);
      console.log("Product transactions response:", response.data);
      setTransactions(response.data);
      setShowTable(true);
    } catch (err) {
      console.error("Error fetching Product transactions:", err);
      setError("Failed to load transactions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Product Report";
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    setProductLoading(true);
    const api_config = {
      method: "get",
      url: `${Config.base_url}Product/GetBy/${CompanyID}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessKey}`,
      },
    };

    try {
      const response = await axios(api_config);
      if (response.data && response.data.status_code === 1) {
        setListOfRecords(response.data.listofProducts || []);
        setTotalRecords(response.data.totalRecords || 0);
        setProductLoading(false);
      } else {
        setProductLoading(false);
        setListOfRecords([]);
      }
    } catch (error) {
      setProductLoading(false);
      setListOfRecords([]);
    }
  };

  const handleProductChange = (value) => {
    if (value === "all") {
      setSelectedProduct(null);
      ProductForm.setFieldsValue({
        code: null,
      });
    } else {
      const product = ListOfRecords.find((product) => product.name === value);
      setSelectedProduct(product);
      if (product) {
        ProductForm.setFieldsValue({
          product: product.name,
        });
      }
    }
  };

  const handleDateChange = (date, dateString) => {
    setStartDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const handleDueDateChange = (date, dateString) => {
    setEndDate(date ? date.format("YYYY-MM-DD") : null);
  };

  const columns = [
    {
      title: "Sr#",
      dataIndex: "",
      key: "SR",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Purchase Quantity",
      dataIndex: "purchaseQuantity",
      key: "purchaseQuantity",
    },
    {
      title: "Purchase Weight",
      dataIndex: "purchaseWeight",
      key: "purchaseWeight",
    },
    {
      title: "Purchase Length",
      dataIndex: "purchaseLength",
      key: "purchaseLength",
    },
    {
      title: "Sale Quantity",
      dataIndex: "saleQuantity",
      key: "saleQuantity",
    },
    {
      title: "Sale Weight",
      dataIndex: "saleWeight",
      key: "saleWeight",
    },
    {
      title: "Sale Length",
      dataIndex: "saleLength",
      key: "saleLength",
    },
    {
      title: "Default Unit",
      dataIndex: "defaultUnit",
      key: "defaultUnit",
    },
    {
      title: "Balance Quantity",
      dataIndex: "balanceQuantity",
      key: "balanceQuantity",
    },
    {
      title: "Balance Weight",
      dataIndex: "balanceWeight",
      key: "balanceWeight",
    },
    {
      title: "Balance Length",
      dataIndex: "balanceLength",
      key: "balanceLength",
    },
  ];

  // Manage state for selected columns (default all columns selected)
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key)
  );

  // Handle checkbox change
  const handleCheckboxChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  // Filter columns based on selected checkboxes
  const columnsToShow = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  // const summary = () => {
  //   let totalAmount = 0;

  //   transactions.forEach(({ amount }) => {
  //     totalAmount += amount || 0;
  //   });

  //   return (
  //     <Table.Summary.Row>
  //       <Table.Summary.Cell colSpan={10}>Total</Table.Summary.Cell>
  //       <Table.Summary.Cell>{totalAmount.toFixed(2)}</Table.Summary.Cell>

  //       <Table.Summary.Cell>
  //         {/* Balance can be calculated if needed */}
  //       </Table.Summary.Cell>
  //     </Table.Summary.Row>
  //   );
  // };

  const printTable = () => {
    const isAllProductsSelected = selectedProduct === "All Products";

    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      "<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/antd/4.21.7/antd.min.css' />"
    );
    printWindow.document.write("</head><body style='text-align: center;'>");
    printWindow.document.write(
      "<h1 style='text-align: center;'>Product Report</h1>"
    );

    // Conditionally render Product name if "All Products" is NOT selected
    if (!isAllProductsSelected) {
      printWindow.document.write(
        "<h3>Product: " + (selectedProduct?.name || "All Products") + "</h3>"
      );
      printWindow.document.write(
        "<h3>Product Code: " + (selectedProduct?.code || "N/A") + "</h3>"
      );
    } else if (isAllProductsSelected) {
      printWindow.document.write("<h3>All Products</h3>");
    }

    if (startDate && endDate) {
      printWindow.document.write(
        "<h3>Date Range: " + startDate + " - " + endDate + "</h3>"
      );
    }

    printWindow.document.write(
      "<div style='display: flex; justify-content: center;'>"
    );
    printWindow.document.write(document.querySelector(".ant-table").outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const menu = (
    <Menu>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "10px",
          padding: "10px",
        }}
      >
        {columns.map((col) => (
          <div
            key={col.dataIndex}
            style={{ display: "flex", alignItems: "start" }}
          >
            <Checkbox
              checked={selectedColumns.includes(col.dataIndex)}
              onChange={(e) => {
                const newSelected = e.target.checked
                  ? [...selectedColumns, col.dataIndex]
                  : selectedColumns.filter((c) => c !== col.dataIndex);
                setSelectedColumns(newSelected);
              }}
            >
              {col.title}
            </Checkbox>
          </div>
        ))}
      </div>
    </Menu>
  );

  return (
    <>
      <div className="sub-menu-wrap">
        <h5>Product</h5>
        <ProductionMenu />
      </div>

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/products/manage">
                <ArrowLeftIcon />
              </NavLink>
              Product Report
            </h3>
          </div>
          <Form
            layout="vertical"
            size="medium"
            className="form-default"
            form={ProductForm}
            onFinish={fetchProductTransactions}
          >
            <Row gutter={[24, 24]}>
              <Col span={6}>
                <Form.Item name="product" label="Product">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Product"
                    loading={ProductLoading}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent={
                      ProductLoading ? <Spin size="small" /> : null
                    }
                    onSelect={handleProductChange}
                  >
                    <Select.Option value="all">All Products</Select.Option>
                    {ListOfRecords.map((product) => (
                      <Select.Option
                        key={product.code} // still using code as key, but not displaying it
                        value={product.name} // use only the name as the value
                      >
                        {product.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="period"
                  label="Date Range"
                  onChange={(value) => setPeriod(value)}
                  initialValue="last60Days"
                >
                  <Select placeholder="Date Range">
                    <Select.Option value="all">All Dates</Select.Option>
                    <Select.Option value="custom">Custom</Select.Option>
                    <Select.Option value="today">Today</Select.Option>
                    <Select.Option value="week">This Week</Select.Option>
                    <Select.Option value="month">This Month</Select.Option>
                    <Select.Option value="last60Days">
                      Last 60 Days
                    </Select.Option>
                    <Select.Option value="year">This Year</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Form.Item name="code" label="Code" hidden>
                <Input />
              </Form.Item>

              <Col span={6}>
                <Form.Item name="startDate" label="From Date">
                  <DatePicker
                    value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                    style={{ width: "100%" }}
                    onChange={handleDateChange}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item name="endDate" label="To Date">
                  <DatePicker
                    value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                    style={{ width: "100%" }}
                    onChange={handleDueDateChange}
                  />
                </Form.Item>

                <br />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="small"
                    style={{ marginLeft: "180px", marginBottom: "10px" }}
                    loading={loading}
                  >
                    Run Report
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {showTable && transactions.length > 0 && (
            <>
              <Button
                type="default"
                onClick={printTable}
                style={{ marginBottom: 16 }}
              >
                Print Report
              </Button>
              <div className="filter-dropdown">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="top"
                  overlayStyle={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <span>
                    <Tooltip>
                      <FilterFilled
                        style={{
                          fontSize: "24px",
                          color: "#1890ff",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      />
                    </Tooltip>
                  </span>
                </Dropdown>
              </div>
              <div style={{ textAlign: "center", marginBottom: "16px" }}>
                <h3>
                  Product:{" "}
                  {selectedProduct ? selectedProduct.name : "All Products"}
                </h3>
                {selectedProduct && (
                  <h3>Product Code: {selectedProduct.code}</h3>
                )}
              </div>
              <Table
                columns={columnsToShow}
                dataSource={transactions}
                rowKey={(record) => record.id || Math.random()}
                // summary={summary}
                pagination={false}
              />
            </>
          )}

          {showTable && transactions.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </>
  );
};

export default ProductReport;
